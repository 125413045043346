import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => {
  return(
    <>
      <header
        style={{
          background: `#cc7756`,
        }}
      >
        <div
          className="site-header-wrapper"
        >
          <div className="support-button-wrapper">
            <Link
                className="support-button"
                to="/tamogass-minket"
                style={{
                  textDecoration: `none`,
                }}
              >
                Támogatás
            </Link>
          </div>
          <div className="site-name-wrapper">
            <h3 style={{ margin: 0 }}>
              <Link
                to="/"
                style={{
                  color: `white`,
                  textDecoration: `none`,
                }}
              >
                {siteTitle}
              </Link>
            </h3>
          </div>
          <div className="site-social-wrapper">
            <a className="site-social-button" href="https://www.facebook.com/Annyiavil%C3%A1ghu-102039428452206" target="_blank"><i class="fab fa-facebook-square"></i></a>
            <a className="site-social-button" href="https://www.instagram.com/annyiavilag.hu" target="_blank"><i class="fab fa-instagram-square"></i></a>
          </div>
        </div>
      </header>

      <div 
        className="top-header-nav"
        style={{
          marginBottom: `1.45rem`,
        }}
      >
        <Link to="/" className="top-header-link">Főoldal</Link>
        <Link to="/hirek" className="top-header-link">Hírek</Link>
        <Link to="/kit-tamogassak" className="top-header-link">Adományozás</Link>
        <Link to="/hasznos-szolgaltatasok" className="top-header-link">Szolgáltatások</Link>
        <Link to="/hasznos-terkepek" className="top-header-link">Térképek</Link>
        <Link to="/kozossegi-jatekok" className="top-header-link">Közösségi Játékok</Link>
        <Link to="/jatekok" className="top-header-link">Játékok</Link>
        <Link to="/tamogass-minket" className="top-header-link highlight-mobile">Támogass Minket</Link>
      </div>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
