/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import CookieConsent from "react-cookie-consent";
import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1200,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>
        <footer
          className="text-center"
          style={{
            marginTop: `2rem`,
          }}
        >
          <Link to="/tamogass-minket" className="top-header-link">Támogatás</Link><Link to="/sutitajekoztato" className="top-header-link">Sütitájékoztató</Link><br/>
          &copy; {new Date().getFullYear()} Minden jog fenntartva. Szeretettel <i class="fas fa-heart heart-upcolor"></i> [annyiavilag.hu, annyiavilág.hu] - <span class="support-highlight-btn">hello@annyiavilag.hu</span>
        </footer>
      </div>
      <CookieConsent
        location="bottom"
        buttonText="ELFOGAD"
        cookieName="annyiavilagCookieDrop"
        style={{ background: "#000" }}
        buttonStyle={{ color: "#4e503b", fontSize: "12px" }}
        expires={150}
        >
        <span style={{ fontSize: "13px" }}>Ez a weboldal sütiket használ a kényelmesebb böngészés érdekében. A honlap használatával Ön elfogadja, hogy az oldal sütiket használ.{" "}</span>
        <span style={{ fontSize: "13px" }}>Kérjük, olvassa el a <Link to="/sutitajekoztato" className="footer-cookie-link">sütitájékoztatónkat</Link>,amelyben további információkat olvashat a sütikről és azt is megtudhatja, hogyan tudja kikapcsolni vagy törölni őket.</span>
      </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
